<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-checkbox
                    v-model="inputVal"
                    :state="getValidationState(validationContext)"
                    v-bind="$props"
                    :disabled="readonly"
                    switch
                    value="1"
                    unchecked-value="0"
                ></b-form-checkbox>
                <slot name="append-slot"/>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    name: 'ZwSwitchGroup',
    props: {
        'name': {
            type: String,
            required: true
        },
        'value': [String, Number],
        'validate': [String, Object],
        'icon': [String, Array],
        'type': String,
        'label': String,
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'trueValue': {
            type: [Boolean,String],
            default: () => '1'
        },
        'falseValue': {
            type: [Boolean,String],
            default: () => '0'
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>