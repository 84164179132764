<template>
    <div>
        <div v-if="isSidebarOpen && !isDraggable" class="custom-backdrop" @click="close"></div>
        <b-sidebar @shown="sidebarShown" ref="sidebar" title="test"
                   :width="typeof width === 'string' ? width : (width+'%')"
                   :id="id"
                   :z-index="zIndex"
                   right
                   no-close-on-esc
                   shadow
        >
            <template #header>
                <div style="display: flex;width: 100%" @mousedown="startDrag">
                    <slot name="sidebar-title">
                        <strong :class="titleClass" style="font-size: 14px">{{ title }}</strong>
                    </slot>
                    <div style="margin-left: auto;margin-right: unset;text-align: right;min-width: 100px;">
                        <b-button class="close mr-2"
                                  @click="minimize"
                                  style="padding-right: 5px;padding-left: 5px"
                        >
                            <font-awesome-icon size="xs" icon="window-restore"
                            />
                        </b-button>
                        <b-button-close v-if="showClose"
                                        @click="close"
                        />
                    </div>
                </div>
            </template>
            <template>
                <template v-if="disableWrapper">
                    <slot></slot>
                </template>
                <div v-else class="px-2 py-1">
                    <div v-if="openedModals.length">
                        <div v-for="(modal,index) in openedModals"
                             :key="index"
                             class="card bg-warning mb-1"
                        >
                            <div class="card-body">
                                <div class=" text-uppercase font-weight-bold">
                                    <font-awesome-icon class="mr-2" icon="info"/>
                                    {{ $t('common.toasts.form_another_user') }} {{ modal.user.id }} {{
                                        modal.user.email
                                    }}
                                    ({{ modal.last_activity | formatDate('DD.MM.YYYY HH:mm:ss') }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <slot></slot>
                </div>
            </template>
            <div class="resize-handle" v-if="minimized" @mousedown="startResize"></div>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    name: 'zw-sidebar',
    props: {
        'title': {
            type: String,
        },
        'titleClass': {
            type: String,
            default: ''
        },
        'disableWrapper': {
            type: Boolean,
            default: false
        },
        'showClose': {
            type: Boolean,
            default: true
        },
        'callbackClose': {
            type: Function,
        },
        isDraggable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            width: 95,
            zIndex: 1000,
            id: '',
            name: '',
            payload: null,
            interval: null,
            openedModals: [],
            editedSidebars: {},
            isSidebarOpen: false,
            position: {x: 0, y: 20},
            isDragging: false,
            startX: 0,
            startY: 0,
            minimized: false,
            initialModalSettings: {
                width: null
            },
            isResizing: false,
            resizeWidth: null,
            resizeHeight: null,
            resizeStartX: 0,
            resizeStartY: 0,
        }
    },
    methods: {
        minimize() {
            this.$children[0].$refs['content'].classList.toggle('minimized-modal')
            if (this.minimized) {
                this.minimized = false
                this.isDraggable = false

                this.$children[0].$refs['content'].style.width = this.initialModalSettings.width
                this.$children[0].$refs['content'].style.height = '100%'
                this.$children[0].$refs['content'].style.minHeight = '100%'
                this.$children[0].$refs['content'].style.top = 0
                this.$children[0].$refs['content'].style.left = this.initialModalSettings.left
            } else {
                this.minimized = true
                this.isDraggable = true

                this.initialModalSettings.width = this.$children[0].$refs['content'].style.width
                this.initialModalSettings.left = this.$children[0].$refs['content'].style.left

                this.$children[0].$refs['content'].style.width = '50%'
                this.$children[0].$refs['content'].style.height = '70%'
                this.$children[0].$refs['content'].style.minHeight = '10%'
                this.$children[0].$refs['content'].style.left = (window.innerWidth / 2 - this.$children[0].$refs['content'].getBoundingClientRect().width / 2) + 'px'
                this.$children[0].$refs['content'].style.top = (window.innerHeight / 2 - this.$children[0].$refs['content'].getBoundingClientRect().height / 2) + 'px'

                this.position.x = this.$children[0].$refs['content'].getBoundingClientRect().left
                this.position.y = this.$children[0].$refs['content'].getBoundingClientRect().top

                this.resizeWidth = this.$children[0].$refs['content'].getBoundingClientRect().width
                this.resizeHeight = this.$children[0].$refs['content'].getBoundingClientRect().height
            }
        },
        startDrag(event) {
            if (this.minimized) {
                this.isDragging = true;
                this.startX = event.clientX - this.position.x;
                this.startY = event.clientY - this.position.y;

                document.addEventListener("mousemove", this.onDrag);
                document.addEventListener("mouseup", this.stopDrag);
            }
        },
        onDrag(event) {
            if (this.isDragging) {
                this.position.x = event.clientX - this.startX;
                this.position.y = event.clientY - this.startY;

                this.$children[0].$refs['content'].style.top = this.position.y + 'px'
                this.$children[0].$refs['content'].style.left = this.position.x + 'px'
            }
        },
        stopDrag() {
            this.isDragging = false;

            document.removeEventListener("mousemove", this.onDrag);
            document.removeEventListener("mouseup", this.stopDrag);
        },
        startResize(event) {
            console.log('startResize')
            this.isResizing = true;
            this.resizeStartX = event.clientX;
            this.resizeStartY = event.clientY;

            document.addEventListener("mousemove", this.handleResize);
            document.addEventListener("mouseup", this.stopResize);
        },
        handleResize(event) {
            if (!this.isResizing) return;

            const dx = event.clientX - this.resizeStartX;
            const dy = event.clientY - this.resizeStartY;

            this.resizeWidth = Math.max(50, this.resizeWidth + dx);
            this.resizeHeight = Math.max(50, this.resizeHeight + dy);

            console.log('handleResize', this.resizeWidth)
            console.log('handleResize', this.resizeHeight)

            this.$children[0].$refs['content'].style.width = this.resizeWidth + 'px'
            this.$children[0].$refs['content'].style.height = this.resizeHeight + 'px'

            this.resizeStartX = event.clientX;
            this.resizeStartY = event.clientY;
        },
        stopResize() {
            console.log('stopResize')
            this.isResizing = false;

            document.removeEventListener("mousemove", this.handleResize);
            document.removeEventListener("mouseup", this.stopResize);
        },
        close() {
            clearInterval(this.interval)
            this.interval = null

            if (this.callbackClose) {
                this.callbackClose()
            }
            clearInterval(this.interval)
            this.interval = null

            let result = this.$root.$children[0].closeModal(this.$refs['sidebar'], true)
            if (result) {
                this.isSidebarOpen = false;
            }
        },
        sidebarShown() {
            this.isSidebarOpen = true;
            this.modalOpened()
            this.interval = window.setInterval(() => {
                this.modalOpened()
            }, 30000)

            this.$emit('shown')
        },
        modalOpened() {
            if (this.name) {
                window.axios.post(
                    window.apiUrl + '/common-data/modals',
                    {modal: this.name, params: this.payload},
                    {'skip_loading': true}
                )
                    .then(response => {
                        if (response.data.opened) {
                            this.openedModals = response.data.opened
                        } else {
                            this.openedModals = []
                        }
                    })
            }
        }
    }
    ,
    mounted() {
        this.$nextTick(() => {
            this.$root.$children[0].openModalCallback()
        })
    }
}
</script>
<style>
.custom-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2001; /* Ensure it covers the sidebar */
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
}
.minimized-modal {
    border: solid 1px lightgray;
}
</style>