import {
    commonStoreFetch,
    commonStoreSave,
    commonTableFetch
} from "@/store/common-functions";

function getDefaultState() {
    return {
        documentMailData: null,
        mailMessageData: null,
        mailsTable: [],
    }
}

const getters = {
    getDocumentMailData: state => state.documentMailData,
    getMailMessageData: state => state.mailMessageData,
    getMailsTable: state => state.mailsTable,
}

const actions = {
    fetchDocumentMailData({commit}, data) {
        return commonStoreFetch(commit, '/mail/document/' + data.id + '/type/' + data.type, 'setDocumentMailData')
    },
    fetchMailMessageData({commit}, data) {
        return commonStoreFetch(commit, '/mail/message/' + data.id, 'setMailMessageData')
    },
    sendDocumentEmail({_}, data) {
        return commonStoreSave('/mail/document/' + data.document_id, data)
    },
    sendEmails({_}, data) {
        return commonStoreSave('/mails/send', data)
    },
    fetchMails({commit, _}, params) {
        return commonTableFetch(commit, '/mail/sent', 'setMailsTable', params)
    },
}

const mutations = {
    setDocumentMailData(state, data) {
        state.documentMailData = data.data
    },
    setMailMessageData(state, data) {
        state.mailMessageData = data.data
    },
    setMailsTable(state, data) {
        state.mailsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}