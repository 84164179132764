<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t((labelPrefix ? labelPrefix : 'common.label.') + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>

            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-select v-model="inputVal"
                               :options="options"
                               :state="getValidationState(validationContext)"
                               :text-field="textField"
                               :value-field="valueField"
                               :disabled="readonly"
                               :multiple="multiple"
                               v-on="$listeners"
                               :size="size"
                >
                    <template v-if="empty" #first>
                        <b-form-select-option :value="null" :disabled="emptyDisabled">-</b-form-select-option>
                    </template>
                </b-form-select>
                <slot name="append-slot"/>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            <b-form-text v-if="helpText">{{helpText}}</b-form-text>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'size': {
            type: String,
            default: 'lg'
        },
        'value': {},
        'form': {},
        'name': {},
        'label': {},
        'options': {},
        'validate': {},
        'icon': {},
        'textField': {},
        'valueField': {},
        'labelPrefix': {},
        'readonly': {},
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'multiple': {
            type: Boolean,
            default: false
        },
        'empty': {
            type: Boolean,
            default: false
        },
        'emptyDisabled': {
            type: Boolean,
            default: true
        },
        'helpText': {
            type: String,
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                // this.$emit('input', val);
            }
        }
    },
}
</script>