<template>
    <validation-provider :rules="validate" v-slot="validationContext">
        <ckeditor
            v-model="inputVal"
            ref="ckeditor"
            :config="mode == 'simple' ? simpleCkeditorConfig : ckeditorConfig"
            v-bind="$props"
            @ready="onEditorReady()"
        ></ckeditor>
        <custom-slot v-if="!disableAfterZwCkeditor" id="after-zw-ckeditor" :base="getThis()"></custom-slot>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'value': [String, Number],
        'mode': {
            type: String,
            default: 'standard'
        },
        'validate': [String, Object],
        'api_url': {type: String},
        'disable-after-zw-ckeditor': {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            simpleCkeditorConfig: {
                filebrowserUploadMethod: 'xhr',
                extraPlugins: 'uploadimage,justify,image2,font',
                removePlugins: 'image',
                toolbarGroups: [
                    {name: 'undo', groups: ['undo']},
                    {name: 'links'},
                    {name: 'insert'},
                    {name: 'document', groups: ['mode']},
                    {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
                    {name: 'paragraph', groups: ['list', 'align']},
                    {name: 'styles'},
                ],
                removeButtons: 'Anchor,Underline,Subscript,Superscript,Image,Format,Styles',
                filebrowserUploadUrl: (this.api_url ?? window.apiUrl) + '/ckeditor/upload',
                filebrowserBrowseUrl: '#ckeditor',
                versionCheck: false,
            },
            ckeditorConfig: {
                filebrowserUploadMethod: 'xhr',
                extraPlugins: 'uploadimage,justify,image2,font',
                removePlugins: 'image',
                filebrowserUploadUrl: (this.api_url ?? window.apiUrl) + '/ckeditor/upload',
                filebrowserBrowseUrl: '#ckeditor',
                contentsCss: ['ckeditor.css'],
                basicEntities: false,
                entities: false,
                forceSimpleAmpersand: true,
                allowedContent: true,
                fillEmptyBlocks: false,
                versionCheck: false,
            },
        }
    },
    methods: {
        getThis() {
            return this
        },
        onEditorReady() {
            var ckEditor = this.$refs.ckeditor
            var interval = setInterval(function () {
                let checkImage = '%22%20/%3E%3C/p%3E%0A'
                let extraLine = '<p>&nbsp;</p>';
                if (ckEditor.$_destroyed) {
                    clearInterval(interval);
                }
                let lastRow = ckEditor.instance.getData().slice(-9);
                if (ckEditor.instance.getData() && encodeURI(lastRow) == checkImage) {
                    ckEditor.instance.setData(ckEditor.instance.getData() + extraLine)
                }
            }, 1000, ckEditor);
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>