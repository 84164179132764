<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <input type="hidden" v-model="inputVal">
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else-if="labelHtml"><span v-html="labelHtml"></span></template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>

                <b-button variant="info"
                          v-if="help"
                          v-b-popover.hover.top="help"
                >
                    <font-awesome-icon icon="info"/>
                </b-button>
            </template>
            <div>
                <b-overlay :show="loading"/>
                <template v-if="model">
                    <CBadge color="info"
                            class="mr-2"
                            style="line-height:30px;width:100%"
                    >
                        <b-row>
                            <b-col class="text-left">
                                <font-awesome-icon v-if="getIcon()" class="ml-2 mr-2" :icon="getIcon()"/>
                                <a v-if="!disableOpening" href="#" @click="openModel">
                                    <span style="color:white">{{ model.label }}</span>
                                </a>
                                <span v-else style="color:white">{{ model.label }}</span>
                            </b-col>
                            <b-col class="text-right">
                                <b-button @click="unselect" class="ml-2" variant="info" size="sm">
                                    <font-awesome-icon icon="times"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CBadge>
                </template>
                <template v-else>
                    <vue-typeahead-bootstrap
                        ref="typeahead"
                        v-model="search"
                        :data="data"
                        :class="!getValidationState(validationContext) ? 'is-invalid' : 'is-valid'"
                        :inputClass="(getValidationState(validationContext) == null ? '' :(getValidationState(validationContext) === true ? 'is-valid' : 'is-invalid')) + inputClass"
                        :serializer="serializer"
                        :minMatchingChars="1"
                        @hit="optionSelected($event)"
                        :size="size"
                    >
                        <template slot="prepend">
                            <b-input-group-prepend is-text>
                                <font-awesome-icon icon="search"/>
                            </b-input-group-prepend>
                        </template>
                        <template slot="append">
                            <b-button @click="openSelectModal" variant="info">
                                <font-awesome-icon icon="list"/>
                            </b-button>
                        </template>
                    </vue-typeahead-bootstrap>
                </template>
            </div>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'
import _ from "underscore";

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        'modelType': {
            type: String,
            required: true
        },
        'value': [String, Number, Object],
        'validate': [String, Object],
        'size': {
            type: String,
            default: null
        },
        'help': String,
        'label': String,
        'labelHtml': String,
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'disableOpening': {
            type: Boolean,
            default: false
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'callback': [Function],
        'additionalParams': {
            type: [Object],
            default: () => {
                return {}
            }
        },
        'inputClass': {
            type: String,
            default: '',
        },
        'serializer': {
            type: Function,
            default: s => s.label
        },
    },
    data() {
        return {
            loading: true,
            model: null,
            search: '',
            data: [],
        }
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    mounted() {
        if (this.value) {
            this.start()
        } else {
            this.loading = false
        }
    },
    methods: {
        getData(query) {
            if (!query || !query.length) {
                return
            }
            let params = this.additionalParams
            params.search = query
            this.axios.post(this.getApiUrl(), params, {'skip_loading': true})
                .then(response => {
                    this.data = response.data
                })
        },
        optionSelected(option) {
            this.$nextTick(() => {
                this.model = option
                this.search = null

                this.$emit('input', option.id)
                if (this.callback && option) {
                    this.callback(option)
                }
            })
        },
        unselect() {
            this.model = null
        },
        openSelectModal() {
            const selectModals = {
                'customer': 'select-customer-modal',
                'contact-person': 'select-contact-person-modal',
                'contact-person-email': 'select-contact-person-modal',
                'offering': 'select-offering-modal',
                'contract': 'select-contract-modal',
                'customer-contract': 'select-customer-contract-modal',
                'article': 'select-article-modal',
            }

            if (selectModals[this.modelType] ?? null) {
                this.$root.$children[0].openModal(selectModals[this.modelType], {additionalParams:this.additionalParams}, (id) => {
                    this.requestById(id)
                })
            }
        },
        getApiUrl() {
            const urls = {
                'customer': window.apiUrl + '/customer-search',
                'offering': window.apiUrl + '/offering/search',
                'contract': window.apiUrl + '/contract-search',
                'contact-person': window.apiUrl + '/contact-person/search',
                'contact-person-email': window.apiUrl + '/contact-person/email-search',
                'customer-contract': window.apiUrl + '/customer-contract-search',
                'article': window.apiUrl + '/articles/search',
            }

            return urls[this.modelType] ?? null
        },
        getIcon() {
            const icons = {
                'customer': 'user',
                'offering': 'file-invoice',
                'contract': 'file-invoice',
                'customer-contract': 'file-invoice',
                'article': 'barcode',
            }

            return icons[this.modelType] ?? null
        },
        openModel() {
            if (this.modelType == 'customer') {
                this.$root.$children[0].openModal('customer-modal', {customerId: this.model.id})
            }

            if (this.modelType == 'offering') {
                this.$root.$children[0].openModal('offering-modal', {id: this.model.id})
            }

            if (this.modelType == 'contract') {
                this.$root.$children[0].openModal('contract-modal', {contractId: this.model.id})
            }

            if (this.modelType == 'article') {
                this.$root.$children[0].openModal('article-modal', {id: this.model.id})
            }

            if (this.modelType == 'customer-contract') {
                // this.$root.$children[0].openModal('offering-modal', {id: this.model.id})
            }
        },
        requestById(id) {
            let params = JSON.parse(JSON.stringify(this.additionalParams))
            params.id = id
            this.loading = true

            this.axios.post(this.getApiUrl(), params)
                .then(response => {
                    this.optionSelected(response.data[0])
                })
                .finally(() => {
                    this.loading = false
                })
        },
        start() {
            if (this.value) {
                this.requestById(this.value)
            }
        }
    },
    watch: {
        search: _.debounce(function (query) {
            this.getData(query)
        }, 250),
        value: function (val) {
            if (!val) {
                this.unselect()
            }
            // if (val) {
            //     this.start()
            // }
        }
    }
}
</script>

<style>
.vbt-autcomplete-list {
    z-index: 3000 !important;
    background-color: #ebedef;
}
</style>