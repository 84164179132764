<template>
    <draggable :class="{[`cursor-grabbing`]: dragging === true}"
               v-model="inputVal"
               group="columns"
               @start="dragging = true"
               @end="dragging = false"
               handle=".dz-preview"
               draggable=".dz-preview"
    >
        <vue-dropzone ref="dz"
                      id="customdropzone"
                      :options="dropzoneOptions"
                      :include-styling="false"
                      @vdropzone-success="uploaded"
                      @vdropzone-removed-file="removed"
                      @vdropzone-thumbnail="thumbnail"
                      use-custom-slot
        >
            <span>Drop files here to upload</span>
        </vue-dropzone>
    </draggable>
</template>

<script>
import Draggable from "vuedraggable"

export default {
    components: {
        Draggable
    },
    props: {
        'value': [String, Array],
        'url': {
            type: String,
            required: true
        },
        'acceptedFiles': {
            type: String,
        },
    },
    data() {
        return {
            dropzoneOptions: {
                url: window.apiUrl + this.url,
                thumbnailWidth: 150,
                maxFilesize: 10,
                previewTemplate: this.template(),
                acceptedFiles: this.acceptedFiles,
                headers: {
                    Authorization: `Bearer ${window.apiToken}`,
                    UserAuthToken: `${window.apiUserToken}`
                },
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME"
            },
            filesMap: {},
            dragging: false,
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.inputVal.forEach(file => {
                this.$refs.dz.manuallyAddFile({
                    size: 1,
                    name: file,
                }, window.apiUrl + '/image/' + file)
            })
        })
    },
    methods: {
        uploaded(file, response) {
            if (!this.inputVal) {
                this.inputVal = []
            }

            this.filesMap[file.upload.uuid] = response.name
            this.inputVal.push(response.name)
        },
        removed(file, error, xhr) {
            if (file.upload) {
                this.inputVal = this.inputVal.filter(name => {
                    return name != this.filesMap[file.upload.uuid]
                })
            } else {
                this.inputVal = this.inputVal.filter(name => {
                    return name != file.name
                })
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-remove"><i class="fa fa-close" data-dz-remove></div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
        },
        thumbnail: function (file, dataUrl) {
            var j, len, ref, thumbnailElement;
            if (file.previewElement) {
                file.previewElement.classList.remove("dz-file-preview");
                ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
                for (j = 0, len = ref.length; j < len; j++) {
                    thumbnailElement = ref[j];
                    thumbnailElement.alt = file.name;
                    thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
                }
                return setTimeout(((function (_this) {
                    return function () {
                        return file.previewElement.classList.add("dz-image-preview");
                    };
                })(this)), 1);
            }
        }
    }
}
</script>

<style>
#customdropzone {
    color: #777;
    transition: background-color .2s linear;
    height: 200px;
    padding: 40px;
    background-color: lightgray;
}

#customdropzone .dz-preview:hover .dz-remove {
    opacity: 1;
}

#customdropzone .dz-preview {
    width: 160px;
    display: inline-block
}

#customdropzone .dz-preview .dz-image {
    width: 100%;
    height: 100px;
    margin-left: 0;
    margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#customdropzone .dz-preview .dz-image > img {
    width: 100%;
}

#customdropzone .dz-preview .dz-details {
    color: white;
    transition: opacity .2s linear;
    text-align: center;
}

#customdropzone .dz-message {
    text-align: center;
}

#customdropzone .dz-remove {
    position: absolute;
    z-index: 30;
    color: white;
    background-color: lightblue;
    margin-left: 15px;
    padding: 10px;
    top: inherit;
    bottom: 15px;
    border: 2px white solid;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 1.1px;
    opacity: 0;
}
</style>