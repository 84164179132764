import Vue from "vue"
import moment from 'moment'

Vue.filter("optionsVV", (data) => {
    let options = []

    if (data) {
        data.forEach(val => {
            options.push({value: val, text: val})
        })
    }

    options.sort((a, b) => a.text.localeCompare(b.text))

    return options
})

Vue.filter("optionsObject", (data) => {
    let options = []

    data.forEach((val, index) => {
        options.push({label: val, id: index})
    })

    return options
})

Vue.filter("optionsVL", (data) => {
    let options = []

    Object.keys(data).forEach(val => {
        options.push({value: val, text: data[val]})
    })

    return options
})

Vue.filter("optionsStatuses", (data) => {
    let options = []

    Object.keys(data).forEach(val => {
        options.push({value: val, text: data[val] + ' (' + val + ')'})
    })

    return options
})

Vue.filter("optionsKeyValue", (data, key, value, nullable = false) => {
    let options = []

    if (nullable) {
        options.push({id: null, label: ''})
    }

    data.forEach((val, index) => {
        options.push({label: val[key], id: val[value]})
    })

    return options
})

Vue.filter("optionsWithKeys", (data, nullable = false) => {
    let options = []

    if (nullable) {
        options.push({value: null, text: ''})
    }

    Object.keys(data).forEach(val => {
        options.push({value: val, text: data[val]})
    })

    options.sort((a, b) => a.text.localeCompare(b.text))

    return options
})

Vue.filter("formatDate", (date, format) => {
    if (date) {
        var correctFormat = detectCorrectFormat(date)
        return moment(date, correctFormat).format(format);
    } else {
        return '-';
    }
})
Vue.filter("formatUpdatedDate", (date, format) => {
    if (date) {
        var correctFormat = detectCorrectFormat(date)
        return 'Updated: ' + moment(date, correctFormat).format(format);
    } else {
        return '-';
    }

})
Vue.filter("timeFormat", (date, format) => {
    return moment.unix(date).format(format);
})
Vue.filter("toFixed", (value) => {
    if (typeof value == "undefined" || !value) {
        value = 0;
    }
    return parseFloat(value).toFixed(2)
})
Vue.filter("priceFormat", (value) => {
    if (typeof value == 'string') {
        value = parseFloat(value.replace(/,/g, '.'));
    }
    let sign = value < 0 ? '-' : '';
    let sNumber = Math.abs(parseInt(value = (+value || 0).toFixed(2))) + "";
    let len = sNumber.length;
    let tchunk = len > 3 ? len % 3 : 0;
    let chFirst = (tchunk ? sNumber.substr(0, tchunk) + '.' : '');
    let chRest = sNumber.substr(tchunk).replace(/(\d\d\d)(?=\d)/g, '$1' + '.');
    let chLast = 2 ? ',' + (Math.abs(value) - sNumber).toFixed(2).slice(2) : '';

    return sign + chFirst + chRest + chLast;
})
Vue.filter("currencyFormat", (value) => {
    if (typeof value == "undefined" || !value) {
        value = '€';
    }
    return value
})

Vue.filter("limit", (value, maxLength) => {
    if (value.length > maxLength) {
        value = value.slice(0, maxLength) + '...';
    }
    return value
})

function detectCorrectFormat(date) {
    var correctFormat = 'DD.MM.YYYY HH:mm'
    dateTimeFormatsWithSeparators.every(val => {
        if (moment(date, val, true).isValid()) {
            correctFormat = val
            return false
        } else {
            return true
        }
    })
    return correctFormat
}

const dateTimeFormatsWithSeparators = [
    'DD.MM.YYYY',
    'YYYY.MM.DD',
    'DD-MM-YYYY',
    'YYYY-MM-DD',
    'DD.MM.YYYY HH:mm',
    'YYYY.MM.DD HH:mm',
    'YYYY-MM-DD HH:mm',
    'DD-MM-YYYY HH:mm',
    'DD.MM.YYYY HH:mm:ss',
    'YYYY.MM.DD HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
    'DD-MM-YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'MM/DD/YYYY HH:mm',
    'YYYY/DD/MM HH:mm',
    'YYYY/MM/DD HH:mm',
];